import { API, graphqlOperation } from "aws-amplify";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import * as customQueries from "../customGraphql/queries";
import {
  ListPartOneTopicsQuery,
  ListPartOnesQuery,
  ListPartTwosQuery,
  ListPartThreesQuery,
  GetPartOneQuery,
  GetPartTwoQuery,
  GetPartThreeQuery,
  GetPartOneTopicQuery,
  CreatePartOneInput,
  CreatePartOneTopicInput,
  CreatePartTwoInput,
  CreatePartThreeInput,
  CreateSpeakingHomeworkInput,
  CreateSpeakingRecordInput,
  HomeworkByStudentQuery,
  UpdateSpeakingRecordInput,
} from "../API";

export const listTableData = async (typeName: string): Promise<any> => {
  switch (typeName) {
    case "PartOneTopic":
      return listPartOneTopics();
    case "PartOne":
      return listPartOnes();
    case "PartTwo":
      return listPartTwos();
    case "PartThree":
      return listPartThrees();
  }
};

export const listPartOneTopics = async (): Promise<ListPartOneTopicsQuery> => {
  return API.graphql({
    query: customQueries.listPartOneTopics,
    variables: { limit: 1000 },
  }) as Promise<ListPartOneTopicsQuery>;
};

export const listPartOnes = async (): Promise<ListPartOnesQuery> => {
  return API.graphql({
    query: queries.listPartOnes,
    variables: { limit: 1000, sortDirection: "DESC", sortField: "createdAt" },
  }) as Promise<ListPartOnesQuery>;
};

export const listPartTwos = async (): Promise<ListPartTwosQuery> => {
  return API.graphql({
    query: customQueries.listPartTwos,
    variables: { limit: 1000 },
  }) as Promise<ListPartTwosQuery>;
};

export const listPartThrees = async (): Promise<ListPartThreesQuery> => {
  return API.graphql({
    query: queries.listPartThrees,
    variables: { limit: 1000 },
  }) as Promise<ListPartThreesQuery>;
};

export const listHomeworkByStudent = async (
  student: string
): Promise<HomeworkByStudentQuery> => {
  return API.graphql({
    query: queries.homeworkByStudent,
    variables: { limit: 1000, student },
  }) as Promise<HomeworkByStudentQuery>;
};

export const listRecords = async (
  student: string,
  typeName: string,
  id: string
) => {
  const input = { student, typeTypeID: { eq: { type: typeName, typeID: id } } };
  return API.graphql(
    graphqlOperation(queries.recordByStudentTypeTypeID, input)
  );
};

export const getPartOneTopic = async (
  id: string | null
): Promise<GetPartOneTopicQuery> =>
  API.graphql(
    graphqlOperation(customQueries.getPartOneTopic, { id })
  ) as GetPartOneTopicQuery;

export const getPartOne = async (id: string | null): Promise<GetPartOneQuery> =>
  API.graphql(graphqlOperation(queries.getPartOne, { id })) as GetPartOneQuery;

export const getPartTwo = async (id: string | null): Promise<GetPartTwoQuery> =>
  API.graphql(
    graphqlOperation(customQueries.getPartTwo, { id })
  ) as GetPartTwoQuery;

export const getPartThree = async (
  id: string | null
): Promise<GetPartThreeQuery> =>
  API.graphql(
    graphqlOperation(queries.getPartThree, { id })
  ) as GetPartThreeQuery;

export const createPartOneTopic = async (
  partOneTopic: CreatePartOneTopicInput
) => {
  return API.graphql(
    graphqlOperation(mutations.createPartOneTopic, {
      input: partOneTopic,
    })
  );
};

export const createPartTwo = async (partTwo: CreatePartTwoInput) => {
  return API.graphql(
    graphqlOperation(mutations.createPartTwo, {
      input: partTwo,
    })
  );
};

export const createPartOneQuestion = async (
  partOneQuestion: CreatePartOneInput
) => {
  return API.graphql(
    graphqlOperation(mutations.createPartOne, {
      input: partOneQuestion,
    })
  );
};

export const createPartThree = async (PartThree: CreatePartThreeInput) => {
  return API.graphql(
    graphqlOperation(mutations.createPartThree, {
      input: PartThree,
    })
  );
};

export const createSpeakingHomework = async (
  hw: CreateSpeakingHomeworkInput
) => {
  return API.graphql(
    graphqlOperation(mutations.createSpeakingHomework, {
      input: hw,
    })
  );
};

export const createRecord = async (record: CreateSpeakingRecordInput) => {
  return API.graphql(
    graphqlOperation(mutations.createSpeakingRecord, {
      input: record,
    })
  );
};

export const updatePartOneTopic = async (
  partOneQuestion: CreatePartOneTopicInput
) => {
  const { id, topic, season } = partOneQuestion;
  const input = { id, topic, season };
  return API.graphql(
    graphqlOperation(mutations.updatePartOneTopic, {
      input,
    })
  );
};

export const updatePartOneQuestion = async (
  partOneQuestion: CreatePartOneInput
) => {
  const { id, question, questionType, season } = partOneQuestion;
  const input = { id, question, questionType, season };
  return API.graphql(
    graphqlOperation(mutations.updatePartOne, {
      input,
    })
  );
};

export const updatePartTwo = async (partTwo: CreatePartTwoInput) => {
  const { id, prompt, subQuestions, season, type } = partTwo;
  const input = { id, prompt, subQuestions, season, type };
  console.log("update part 2 input", input);
  return API.graphql(
    graphqlOperation(mutations.updatePartTwo, {
      input,
    })
  );
};

export const updatePartThree = async (partThree: CreatePartThreeInput) => {
  const { id, question, type, season } = partThree;
  const input = { id, question, type, season };
  return API.graphql(
    graphqlOperation(mutations.updatePartThree, {
      input,
    })
  );
};

export const updateSpeakingHomework = async (
  SpeakingHomework: CreateSpeakingHomeworkInput
) => {
  const { id, student, title, dueDate, instruction, p1, p2, p3, p1t, p2n3 } =
    SpeakingHomework;
  const input = {
    id,
    student,
    title,
    dueDate,
    instruction,
    p1,
    p2,
    p3,
    p1t,
    p2n3,
  };
  return API.graphql(
    graphqlOperation(mutations.updateSpeakingHomework, {
      input,
    })
  );
};

export const updateRecord = async (input: UpdateSpeakingRecordInput) => {
  return API.graphql(
    graphqlOperation(mutations.updateSpeakingRecord, {
      input,
    })
  );
};

export const updateSpeakingRecordText = async (text: string, id: string) => {
  const input = { id, text };
  return API.graphql(
    graphqlOperation(mutations.updateSpeakingRecord, {
      input,
    })
  );
};

export const updateFinalNote = async (id: string, text: string) => {
  const input = { id, note: text };
  return API.graphql(
    graphqlOperation(mutations.updateSpeakingRecord, {
      input,
    })
  );
};

export const deletePartOneTopic = async (id: string) => {
  return API.graphql(
    graphqlOperation(mutations.deletePartOneTopic, {
      input: { id },
    })
  );
};

export const deletePartOneQuestion = async (id: string) => {
  return API.graphql(
    graphqlOperation(mutations.deletePartOne, {
      input: { id },
    })
  );
};

export const deletePartTwo = async (id: string) => {
  return API.graphql(
    graphqlOperation(mutations.deletePartTwo, {
      input: { id },
    })
  );
};

export const deletePartThree = async (id: string) => {
  return API.graphql(
    graphqlOperation(mutations.deletePartThree, {
      input: { id },
    })
  );
};

export const deleteSpeakingHomework = async (id: string) => {
  return API.graphql(
    graphqlOperation(mutations.deleteSpeakingHomework, {
      input: { id },
    })
  );
};

export const deleteRecord = async (id: string) => {
  return API.graphql(
    graphqlOperation(mutations.deleteSpeakingRecord, {
      input: { id },
    })
  );
};

export const getSpeakingQuestionsByTypeNameAndId = async (
  typeName: string,
  id: string
) => {
  switch (typeName) {
    case "PartOne":
      return getPartOne(id);
    case "PartTwo":
      return getPartTwo(id);
    case "PartThree":
      return getPartThree(id);
    case "PartOneTopic":
      return getPartOneTopic(id);
  }
};
