import {
  Button,
  TextField,
  Box,
  AccordionActions,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import { useState, useEffect, useRef } from "react";
import AudioPlayer from "./AudioPlayer";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Storage } from "aws-amplify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { getCurrentFormattedTime } from "../utilities";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createRecord } from "../utilities/request";
import { useSnackbar } from "notistack";
import file2audio from "../utilities/file2audio";
import audioBufferToWav from "../utilities/audioBufferToWav";

interface RecorderProps {
  student: string;
  type: string;
  id: string;
  backToPractice: () => void;
}

interface RecorderState {
  title: string;
  student: string;
  type: string;
  typeID: string;
  audio: string;
  text: string;
  final: boolean;
}

const Recorder: React.FC<RecorderProps> = ({
  student,
  type,
  id,
  backToPractice,
}) => {
  const initialRecorderState = {
    title: "",
    student,
    type,
    typeID: id,
    audio: "",
    text: "",
    final: false,
  };
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [state, setState] = useState<RecorderState>(initialRecorderState);

  const createRecordMutation = useMutation({
    mutationFn: (input: RecorderState) => createRecord(input),
    onSuccess: (data: any) => {
      console.log("successfully created record", data);
      queryClient.invalidateQueries({
        queryKey: ["speakingRecord", student, type, id],
      });
      enqueueSnackbar("Record was created successfully", {
        variant: "success",
      });
      onCancel();
    },
    onError: (error: any) => {
      console.log("Record creation error", error);
      enqueueSnackbar("Error while creating Record, please try again later", {
        variant: "error",
      });
    },
  });

  const [audioUrl, setAudioUrl] = useState<string>("");
  const [saving, setSaving] = useState<boolean>(false);
  const recorderControls = useAudioRecorder();
  // const { mediaRecorder, recordingTime, stopRecording } = recorderControls;
  const { recordingTime, stopRecording } = recorderControls;
  const audioContext = useRef<AudioContext | null>(null);

  const onRecordingComplete = async (blob: any) => {
    // stopListening();
    try {
      setLoading(true);
      // Create AudioContext if it doesn't exist
      if (!audioContext.current) {
        audioContext.current = new (window.AudioContext ||
          (window as any).webkitAudioContext)();
      }

      // Convert blob to ArrayBuffer
      const arrayBuffer = await blob.arrayBuffer();

      // Decode audio data
      const audioBuffer = await audioContext.current.decodeAudioData(
        arrayBuffer
      );

      // Create WAV file
      const wavFile = audioBufferToWav(audioBuffer);

      // Create a new Blob with WAV data
      const wavBlob = new Blob([wavFile], { type: "audio/wav" });

      // Create File object
      const audioFile = new File([wavBlob], "record.wav", {
        type: "audio/wav",
      });

      const text = await file2audio(audioFile);
      setLoading(false);
      setState((preState) => ({
        ...preState,
        text: preState.text + " " + text,
      }));

      const url = URL.createObjectURL(wavBlob);
      setAudioUrl(url);
    } catch (error) {
      console.error("Error processing audio:", error);
      enqueueSnackbar("Error processing audio. Please try again.", {
        variant: "error",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (recordingTime > 300) {
      stopRecording();
      // stopListening();
    }
  }, [recordingTime]);

  const onCancel = () => {
    setState(initialRecorderState);
    // resetTranscript();
    setAudioUrl("");
    setSaving(false);
    backToPractice();
  };
  const onSubmit = async () => {
    try {
      setSaving(true);
      const response = await fetch(audioUrl);
      const blob = await response.blob();
      const result = await Storage.put(
        `${student}/${getCurrentFormattedTime()}-${state.title}.mp3`,
        blob,
        {
          contentType: blob.type,
          useAccelerateEndpoint: true,
        }
      );
      const input = { ...state, audio: result.key };
      setState(input);
      createRecordMutation.mutate(input);
    } catch (error) {
      console.log("fetch key error", error);
      enqueueSnackbar("Error while Submiting Record, please try again later", {
        variant: "error",
      });
      setSaving(false);
    }
  };
  const isValidated = audioUrl && state.text && state.title;

  return (
    <>
      <AccordionDetails sx={{ p: { sx: 0, sm: 1, md: 2 } }}>
        <Box sx={{ py: 2 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              justifyContent: "space-between",
              alignItems: { xs: "flex-start", sm: "center" },
              mb: 2,
            }}
          >
            <TextField
              label="Title"
              value={state.title}
              onChange={(e) => setState({ ...state, title: e.target.value })}
              sx={{ display: "block", mb: { xs: 2, sm: 0 } }}
              autoFocus
            ></TextField>
            {/* {isReady && ( */}
            <AudioRecorder
              onRecordingComplete={onRecordingComplete}
              audioTrackConstraints={{
                noiseSuppression: true,
                echoCancellation: true,
              }}
              downloadOnSavePress={false}
              downloadFileExtension="wav"
              showVisualizer={true}
              classes={{ AudioRecorderClass: "audioRecording" }}
              recorderControls={recorderControls}
            />
            {/* )} */}
          </Box>

          {audioUrl && <AudioPlayer src={audioUrl} />}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            {loading && <CircularProgress size={16} />}
          </Box>

          {state.text && (
            <CKEditor
              editor={Editor}
              data={state.text}
              onChange={(_event, editor) => {
                const data = editor.getData();
                setState({ ...state, text: data });
              }}
            />
          )}
        </Box>
      </AccordionDetails>
      <AccordionActions>
        <Button variant="outlined" onClick={onCancel} sx={{ mr: 3 }}>
          Cancel
        </Button>
        <Button
          variant="outlined"
          disabled={!isValidated || saving}
          onClick={onSubmit}
        >
          {saving ? "Saving..." : "Submit"}
        </Button>
      </AccordionActions>
    </>
  );
};

export default Recorder;
