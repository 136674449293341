import { useState, useEffect, useRef } from "react";
import { Box, IconButton, Typography, Collapse, Tooltip } from "@mui/material";
import useAzureToken from "../../hooks/azureTokenHook";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import useStore from "./store";
import SimpleAudioPlayer from "../SimpleAudioPlayer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Loader from "../Loader";
import InfoBox from "../../layouts/InfoBox";

const SamplePlayer: React.FC = () => {
  const { text, voice, wordBoundary, sampleUrl, handleActiveTextChange } =
    useStore((state: any) => ({
      text: state.textList[state.activeIndex].text,
      voice: state.textList[state.activeIndex].voice,
      wordBoundary: state.textList[state.activeIndex].wordBoundary,
      sampleUrl: state.textList[state.activeIndex].sampleUrl,
      handleActiveTextChange: state.handleActiveTextChange,
    }));
  const { token, error: tokenError } = useAzureToken();
  const [showText, setShowText] = useState<boolean>(true);
  const [loadingAudio, setLoadingAudio] = useState<boolean>(false);

  const [pendingTextUpdate, setPendingTextUpdate] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  const handleTimeUpdate = (event: any) => {
    // setCurrentTime(event.target.currentTime);
    const currentTime = event.target.currentTime;
    const currentBoundaryIndex = wordBoundary.findIndex((boundary: any) => {
      const boundaryStartTime = (boundary.privAudioOffset /
        10000 /
        1000) as number; // Convert from ticks to seconds
      const boundaryEndTime = (boundaryStartTime +
        boundary.privDuration / 10000 / 1000) as number; // Convert from ticks to secondsation;
      return currentTime >= boundaryStartTime && currentTime <= boundaryEndTime;
    });
    setActiveIndex(currentBoundaryIndex ? currentBoundaryIndex : null);
  };
  const handleWordClick = (startTime: number, text: string) => {
    if (audioRef.current && token) {
      setLoadingAudio(true);
      audioRef.current.currentTime = startTime;
      audioRef.current.pause();
      const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
        token,
        "eastasia"
      );
      speechConfig.speechSynthesisLanguage = "en-US";
      if (voice === "Jane") {
        speechConfig.speechSynthesisVoiceName = "en-US-AvaMultilingualNeural";
      } else {
        speechConfig.speechSynthesisVoiceName = `en-US-DerekMultilingualNeural`;
      }
      const synthesizer = new sdk.SpeechSynthesizer(speechConfig);
      synthesizer.speakTextAsync(text);
      synthesizer.synthesisCompleted = (sender) => {
        sender.close();
        setLoadingAudio(false);
      };
    } else {
      console.log("token error", tokenError);
    }
  };
  const synthesizeText = () => {
    const wordList = [] as any;

    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(
      token as string,
      "eastasia"
    );
    speechConfig.speechSynthesisLanguage = "en-US";
    if (voice === "Jane") {
      speechConfig.speechSynthesisVoiceName = "en-US-AvaMultilingualNeural";
    } else {
      speechConfig.speechSynthesisVoiceName = `en-US-DerekMultilingualNeural`;
    }

    speechConfig.requestWordLevelTimestamps();
    // @ts-ignore
    const synthesizer = new sdk.SpeechSynthesizer(speechConfig, null);
    synthesizer.wordBoundary = function (_sender, eventArgs) {
      wordList.push(eventArgs);
    };
    synthesizer.speakTextAsync(text, (result: any) => {
      const { audioData } = result;
      synthesizer.close();
      // convert audioData (ArrayBuffer) to Blob
      const audioBlob = new Blob([audioData], { type: "audio/wav" });
      // create a blob URL
      const blobUrl = URL.createObjectURL(audioBlob);
      handleActiveTextChange("sampleUrl", blobUrl);
    });
    synthesizer.synthesisCompleted = (sender) => {
      handleActiveTextChange("wordBoundary", [...wordList]);
      sender.close();
    };
  };

  useEffect(() => {
    if (token && !wordBoundary) {
      synthesizeText();
      setPendingTextUpdate(false);
    } else if (!token && !wordBoundary) {
      setPendingTextUpdate(true);
    } else {
      setPendingTextUpdate(false);
    }
  }, [text, wordBoundary]);
  useEffect(() => {
    if (token && pendingTextUpdate) {
      synthesizeText();
      setPendingTextUpdate(false);
    }
  }, [token]);

  if (!wordBoundary && !sampleUrl) {
    return <Loader />;
  }
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mb: showText ? 0 : 2,
        }}
      >
        {sampleUrl && (
          <SimpleAudioPlayer
            audioURL={sampleUrl}
            onTimeUpdate={handleTimeUpdate}
            audioRef={audioRef}
          />
        )}
        <Tooltip title={showText ? "Hide text" : "Show text"} placement="top">
          <IconButton
            color="primary"
            size="medium"
            sx={{ ml: 2 }}
            onClick={() => setShowText(!showText)}
          >
            {showText ? (
              <VisibilityOffIcon fontSize="medium" />
            ) : (
              <VisibilityIcon fontSize="medium" />
            )}
          </IconButton>
        </Tooltip>
      </Box>
      <Collapse in={showText}>
        <InfoBox>
          <Typography>
            {wordBoundary &&
              wordBoundary.length > 0 &&
              wordBoundary.map((boundary: any, i: number) => {
                const startTime = boundary.privAudioOffset / 10000 / 1000; // Convert from ticks to seconds
                const isActive = i === activeIndex;
                return (
                  <Box component="span" key={i + boundary.privText}>
                    {i === 0 ||
                    boundary.privBoundaryType === "PunctuationBoundary"
                      ? ""
                      : " "}
                    <Box
                      component="span"
                      sx={{
                        backgroundColor: isActive ? "#ffb518" : null,
                        border: isActive ? "1px solid" : null,
                        borderRadius: isActive ? 1 : null,
                        borderColor: "primary",
                        cursor: loadingAudio ? "wait" : "pointer",
                      }}
                      onClick={() =>
                        handleWordClick(startTime, boundary.privText)
                      }
                    >
                      {boundary.privText}
                    </Box>
                  </Box>
                );
              })}
          </Typography>
        </InfoBox>
      </Collapse>
    </Box>
  );
};

export default SamplePlayer;
