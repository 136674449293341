import {
  Box,
  Typography,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import { useState } from "react";
import { useSnackbar } from "notistack";
import useStore from "./store";
import { useLocation } from "react-router-dom";
import { franc } from "franc";

const ShadowingCreation: React.FC = () => {
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const createPractice = useStore((state) => state.createPractice);
  const [text, setText] = useState<string>(
    location.state?.text ? location.state.text : ""
  );
  const [voice, setVoice] = useState<string>("Jane");
  const [separator, setSeparator] = useState<"." | "\n" | "p">(".");

  const handldePractice = () => {
    if (text.split(/\b\w+\b/g).length < 5) {
      enqueueSnackbar("words must be more than 5", {
        variant: "warning",
      });
    } else if (text.split(/\b\w+\b/g).length > 220) {
      enqueueSnackbar("words must be less than 220", {
        variant: "warning",
      });
    } else if (franc(text) !== "eng") {
      enqueueSnackbar("text must be English ONLY", {
        variant: "warning",
      });
    } else {
      createPractice(text, voice, separator);
    }
  };
  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ textAlign: "center", marginBottom: 4 }}
      >
        Create Shadowing Content
      </Typography>

      <TextField
        label="Enter text"
        multiline
        minRows={5}
        sx={{
          display: "block",
          width: "100%",
          marginX: "auto",
        }}
        fullWidth
        value={text}
        onChange={(event) => {
          setText(event.target.value);
        }}
      />
      <FormControl sx={{ marginY: 2, display: "block" }}>
        <FormLabel>Voice:</FormLabel>
        <RadioGroup
          row
          value={voice}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setVoice(event.target.value)
          }
        >
          <FormControlLabel value="Jane" control={<Radio />} label="Female" />
          <FormControlLabel value="Jason" control={<Radio />} label="Male" />
        </RadioGroup>
      </FormControl>
      <FormControl sx={{ marginY: 2, display: "block" }}>
        <FormLabel>Difficulty Level:</FormLabel>
        <RadioGroup
          row
          value={separator}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setSeparator(event.target.value as "." | "\n" | "p")
          }
        >
          <FormControlLabel value={"."} control={<Radio />} label="Sentence" />
          <FormControlLabel
            value={"\n"}
            control={<Radio />}
            label="Paragraph"
          />
          <FormControlLabel value={"p"} control={<Radio />} label="Passage" />
        </RadioGroup>
      </FormControl>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button disabled={!text} onClick={handldePractice} variant="outlined">
          Practice
        </Button>
      </Box>
    </Box>
  );
};

export default ShadowingCreation;
